import React from "react"
import styled from "styled-components"

const StyledMap = styled.iframe`
  width: 100%;
  height: 500px;
  border: none;
`

const Map = () => (
  <StyledMap
    title="map"
    frameborder="0"
    allowfullscreen="false"
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9580.175764093741!2d23.183836!3d53.1094034!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf0d3d696da7d23ca!2sMy%20Wash%20-%20Producent%20myjni%20bezdotykowych!5e0!3m2!1spl!2spl!4v1580385114332!5m2!1spl!2spl"
  ></StyledMap>
)

export default Map
