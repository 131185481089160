import React from "react"
import ContactForm from "../components/Molecules/ContactForm/ContactForm"
import Contact from "../components/Organisms/StaticSections/Contact"
import Map from "../components/Organisms/Map/Map"
import SEO from "../components/seo"
import { localize } from "../components/Atoms/Language"
import {WithIntl} from "../components/Atoms/withTranslate"

const ContactPage = ({language}) => (
  <>
    <SEO
      lang={language}
      title={localize("11")}
      description={localize("225")}
    />
    <div className="container">
      <Contact/>
      <ContactForm />
    </div>
    <Map/>
  </>
)

export default WithIntl()(ContactPage)
