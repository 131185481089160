import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { localize } from "../../Atoms/Language"

const StyledWrapper = styled.section`
  margin-top: 80px;
  padding-bottom: 160px;
  border-bottom: 1px solid ${GlobalColors.gray300};
`

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content:space-between;
  flex-wrap: wrap;
  margin-top: 80px;

  
  & > div {
    & > div {
      margin: 40px 0;
    
      & > span {
        display: block;
        line-height: 2.4rem;
        font-size: 1.8rem;
        
        a {
          text-decoration: none;
          color: inherit;
        }
        
        &:first-child {
          font-weight: 700;
          margin-bottom: 5px;
        }
      }
    }
    
    &:last-child {
      margin-right: 0;
    }
  }
`

const Contact = () => (
  <StyledWrapper>
    <div>
      <h1 className="header1">{localize("203")}</h1>
      <StyledContentWrapper>
        <div>
          <h5 className="header5">{localize("204")}</h5>
          <div>
            <span>My Wash Sp. z o.o. Sp. K.</span>
            <span>{localize("205")}</span>
            <span>{localize("206")}: 966-212-0995</span>
            <span><a href="mailto:biuro@mywash.pl">biuro@mywash.pl</a></span>
          </div>
          <div>
            <span>{localize("207")}</span>
            <span>{localize("208")}</span>
            <span>{localize("209")}</span>
          </div>
          <div>
            <span>{localize("210")}</span>
            <span>{localize("211")} 8:00-16:00</span>
          </div>
        </div>
        <div>
          <h5 className="header5">{localize("212")}</h5>
          <div>
            <span>{localize("213")}</span>
            <span>{localize("214")} <a href="tel:+48606905890">+48 606 905 890</a></span>
            <span>{localize("215")} <a href="mailto:pawel.gryko@mywash.pl">pawel.gryko@mywash.pl</a></span>
            <span>{localize("215")} <a href="mailto:biuro@mywash.pl">biuro@mywash.pl</a></span>
          </div>
          <div>
            <span>{localize("216")}</span>
            <span>{localize("214")} <a href="tel:+48736626401">+48 736 626 401</a></span>
            <span>{localize("215")} <a href="mailto:piotr@mywash.pl">piotr@mywash.pl</a></span>
          </div>
          <div>
            <span>Serwis</span>
            <span>{localize("214")} +48 572 956 655</span>
            <span>{localize("214")} +48 574 944 344</span>
          </div>
        </div>
        <div>
          <h5 className="header5">{localize("218")}</h5>
          <div>
            <span>{localize("219")}</span>
            <span>{localize("214")} <a href="tel:+37063035550">+370 630 35 550</a></span>
            <span>{localize("215")} <a href="mailto:bernard.fudali@mywash.pl">bernard.fudali@mywash.pl</a></span>
          </div>
          <div>
            <span>{localize("221")}</span>
            <span>Damian Waszkuć</span>
            <span>{localize("214")} <a href="tel:+48666120235">+48 666 120 235</a></span>
            <span>{localize("215")} <a href="mailto:damian.waszkuc@mywash.pl">damian.waszkuc@mywash.pl</a></span>
            <span>{localize("215")} <a href="mailto:shop@mywash.pl">shop@mywash.pl</a></span>
          </div>
        </div>
      </StyledContentWrapper>
    </div>
  </StyledWrapper>
)

export default Contact
